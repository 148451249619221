import React from 'react';
import {object} from 'prop-types';
import AniLink from "gatsby-plugin-transition-link/AniLink";
import './artist-card.scss';

const ArtistCard = ({data, to, artistInnerRelated}) => {
    const {
        project_image: image,
        name,
        surname,
        country,
        professions_items: instruments,
    } = data !== undefined && data !== null && data;

    const initialInstruments =  instruments?.map(e => {
           return e.title;
    })

    return (
        <AniLink className='artist__inner__card' to={to} duration={0.7} fade>
            {artistInnerRelated && image
                ?
                <div
                    className='artist__inner__card__img background'
                    style={{backgroundImage: `url(${image})`}}
                    title={`${name} ${surname}'s photo`}
                />
                : image &&
                <div className='artist__inner__card__img' title={`${name} ${surname}'s photo`}>
                    <img className='artist__inner__card__img__avatar' src={`${image}`}
                         alt={`${name} ${surname}'s photo`}/>
                </div>
            }

            <div className='artist__inner__card__text'>
                <h1 className='name'>
                    {name} <br/> {surname}
                </h1>

                {country && <p className='location'>{country}</p>}

                {instruments && instruments.length && (
                    <div className='artist__inner__card__text__instruments'>
                        <h3 className={`title ${initialInstruments.includes('Conductor') && 'd-none'}`}>INSTRUMENT{instruments.length > 1 && 'S'}</h3>
                        <div className='instruments__listing'>
                            {instruments.map((instrument, i) => (
                                <p className='instrument' key={`${instrument.title}-${i}`}>
                                    {instrument.title}
                                </p>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </AniLink>
    );
};

ArtistCard.propTypes = {
    data: object.isRequired,
};
export default ArtistCard;
