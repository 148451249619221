import React, {Fragment, useEffect, useRef, useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import './upcomingProjectsSlider.scss';

import SwiperCore, {Mousewheel, Scrollbar} from 'swiper';
import ProjectCardLayout from "../projectCardLayout";

SwiperCore.use([Scrollbar, Mousewheel]);


const UpcomingProjectsSlider = ({data, showSectionTitle = false, sectionTitle}) => {

    const [swiper, setSwiper] = useState(null);
    const swiperRef = useRef(null);

    const [monthList, setMonthList] = useState(null);

    const swiperParams = {
        speed: 100,
        freeMode: true,
        spaceBetween: '3.4%',
        slidesPerView: 'auto',
    };

    useEffect(() => {
        if (
            swiper &&
            data &&
            swiperRef.current !== null &&
            swiperRef.current.swiper !== null
        ) {
            swiper.update();
        }

        setMonthList(data);
    }, [data]);

    console.log(monthList);

    return (
        <div className='upcomingProjectsSlider'>
            {monthList ? (
                <Swiper
                    {...swiperParams}
                    ref={swiperRef}
                    mousewheel
                    onInit={(swiper) => setSwiper(swiper)}
                    // breakpoints={{
                    //     0: {
                    //         direction: 'horizontal',
                    //     },
                    //     1025: {
                    //         direction: 'vertical',
                    //     },
                    // }}
                >
                    {Object.keys(monthList).map((year, i) =>
                        Object.keys((monthList[year])).map((month, j) =>
                            <Fragment key={i}>
                                <SwiperSlide key={i + '' + j}
                                             className={`monthSliderItem ${showSectionTitle && j === 0 ? 'longWidth' : ''}`}>
                                    <div className='monthName'>
                                        <div className='monthNameBody'>
                                            {(showSectionTitle && j === 0) ? sectionTitle : <p>{month}</p>}
                                            <div className='monthNameLeftLine'/>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                {monthList[year][month].map((project, k) =>
                                    <SwiperSlide
                                        key={i + '' + j + '' + k}
                                        className={project.image ? 'sliderItemImage' : ''}
                                    >
                                        <ProjectCardLayout item={project}/>
                                    </SwiperSlide>
                                )

                                }
                            </Fragment>
                        )
                    )}
                </Swiper>
            ) : <div className='notificationWrapper'>Select at least one category to see our projects</div>}
        </div>
    );
};

export default UpcomingProjectsSlider;
