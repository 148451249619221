const useDate = (date) => {
  const d = new Date(date);
  const weekdays = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  const weekday = d.getUTCDay();

  return {
    weekDay: weekdays[weekday],
    day:
      String(d.getUTCDate()).length < 2
        ? `0${d.getUTCDate()}`
        : d.getUTCDate().toString(),
    month: d.toLocaleString('en-US', { month: 'short' }).toUpperCase(),
    year: d.getUTCFullYear(),
  };
};

export default useDate;
