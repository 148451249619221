import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

function SEO ({
  url,
  description,
  image = `${process.env.BASE_URL}/svgIcons/logo.png`,
  imgWidth = '1200',
  imgHeight = '630',
  squareImg = false,
  title,
  showDefaultTitle = true,
  keywords,
}) {
  return (
        <Helmet
            title={title}
            titleTemplate={`${showDefaultTitle ? 'Music Room |' : ''} ${title}`}
            meta={[
                {
                    name: 'keywords',
                    content: keywords || 'Musicroom, Concert creation, Classical music festival, Music Project Ideas, International Music Festival, Malta International Festival, Classical music artist management, world-famous musicians, Music project, artist engagement agency, classic project management, concert preparation, artistic projects, international classic projects, project facilitation, project arrangements, classical concert/project/festival manager, engage classical artist, project intermediary, classical artists network, classical musicians, classical festivals/tour/recordings, classical program planning, classical artists, soloists, conductors, classical music publishing.',
                },
                {
                    name: 'description',
                    content: description,
                },
                {
                    property: 'og:url',
                    content: `${url}`,
                },
                {
                    property: 'og:title',
                    content: title,
                },
                {
                    property: 'og:description',
                    content: description,
                },
                {
                    property: 'og:image',
                    content: image,
                },
                {
                    property: 'og:image:width',
                    content: !squareImg ? imgWidth : '300',
                },
                {
                    property: 'og:image:height',
                    content: !squareImg ? imgHeight : '300',
                },
                {
                    property: 'og:type',
                    content: 'website',
                },
                {
                    name: 'twitter:card',
                    content: 'summary',
                },
                {
                    name: 'twitter:url',
                    content: `${url}`,
                },
                {
                    name: 'twitter:title',
                    content: title,
                },
                {
                    name: 'twitter:description',
                    content: description,
                },
                {
                    name: 'twitter:image',
                    content: image,
                },
                {
                    name: 'twitter:image:width',
                    content: !squareImg ? imgWidth : '300',
                },
                {
                    name: 'twitter:image:height',
                    content: !squareImg ? imgHeight : '300',
                },
            ]}
        />
  );
}

SEO.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  showDefaultTitle: PropTypes.bool,
};

export default SEO;
