import React, {useEffect, useState} from 'react';
import './loader.scss';

const Loader = () => {
    const [firstLoad, setFirstLoad] = useState(false);

    useEffect(() => {
        let data = sessionStorage.getItem('loaderViewed');

        if (!data) {
            setTimeout(() => {
                sessionStorage.setItem('loaderViewed', 'true');
            }, 6000)
        }
        else {
            setFirstLoad(true)
        }
    }, [])

    return (
        <div className={`loader ${firstLoad ? 'displayNone' : ''}`}>
            <svg version='1.1' id='loaderSvg' xmlns='http://www.w3.org/2000/svg'
                 xmlnsXlink='http://www.w3.org/1999/xlink' x='0px'
                 y='0px' viewBox='0 0 413.8 50' xmlSpace='preserve'>
                <g id='leftSide'>
                    <path id='Path_9' className='st0' d='M3.1,49.7c-2,0-3.1-1-3.1-3.1V3.4c0-2,1-3.1,3.1-3.1c1.4,0,2.6,0.8,3.3,2L23,27.2l0,0L39.6,2.3
	c0.8-1.3,2.2-2,3.7-2c1.6,0,2.5,1,2.5,3.1v43.2c0,2-1,3.1-3.1,3.1s-3.1-1-3.1-3.1V12.9l0,0L26.2,32.6c-0.8,1.3-2.2,2-3.7,2
	c-1.3-0.1-2.4-0.8-3-2L6.1,12.8l0,0v33.8C6.1,48.6,5.1,49.7,3.1,49.7z'/>
                    <path id='Path_10' className='st0' d='M71.7,49.7c-5.6,0-9.7-1.3-12.3-4s-4-6.8-4-12.3v-30c-0.1-1.7,1.1-3.2,2.8-3.3s3.2,1.1,3.3,2.8
	c0,0.2,0,0.3,0,0.5v30c-0.1,2.1,0.2,4.2,0.9,6.1c0.6,1.4,1.7,2.6,3.1,3.1c2,0.7,4,1,6.1,0.9h8.7c2.1,0.1,4.2-0.2,6.1-0.9
	c1.4-0.6,2.6-1.7,3.1-3.1c0.7-2,1-4,0.9-6.1v-30c-0.1-1.7,1.1-3.2,2.8-3.3s3.2,1.1,3.3,2.8c0,0.2,0,0.3,0,0.5v30
	c0,5.6-1.3,9.7-4,12.3c-2.6,2.7-6.8,4-12.3,4L71.7,49.7z'/>
                    <path id='Path_11' className='st0' d='M116.6,49.7c-4.1,0-7.4-0.8-9.7-2.5c-2.3-1.7-3.8-4.3-4.3-7.7c-0.2-0.9,0.1-1.7,0.7-2.4
	c0.6-0.6,1.5-0.9,2.3-0.8c0.8-0.1,1.6,0.2,2.2,0.7c0.5,0.6,0.9,1.4,1,2.3c0.2,1.4,1.1,2.7,2.3,3.4c1.7,0.7,3.6,1,5.5,0.9h10.5
	c3.1,0,5.3-0.6,6.4-1.7c1.1-1.1,1.7-3.2,1.7-6.3c0-3.1-0.6-5.3-1.7-6.4s-3.3-1.7-6.3-1.7h-9c-4.6,0-8-1.1-10.2-3.4
	c-2.2-2.2-3.3-5.7-3.3-10.2c0-4.6,1.1-7.9,3.3-10.1c2.2-2.2,5.6-3.3,10.2-3.3h8.7c3.9,0,6.9,0.8,9.1,2.4c2.3,1.8,3.8,4.4,4.2,7.2
	c0.2,0.8,0,1.7-0.6,2.4c-0.7,0.6-1.5,0.9-2.4,0.8c-0.8,0.1-1.6-0.2-2.2-0.8c-0.5-0.6-0.9-1.4-0.9-2.2c-0.3-1.3-1.1-2.3-2.2-2.9
	c-1.6-0.6-3.2-0.9-4.9-0.8H118c-2.9,0-4.9,0.5-5.9,1.5s-1.5,3-1.5,5.8c0,2.9,0.5,4.8,1.5,5.9c1,1.1,3,1.6,5.9,1.6h9
	c4.8,0,8.3,1.2,10.7,3.5c2.3,2.3,3.5,5.9,3.5,10.8c0,4.8-1.2,8.4-3.5,10.7c-2.3,2.3-5.9,3.4-10.7,3.4L116.6,49.7z'/>
                    <path id='Path_12' className='st0' d='M152.6,49.6c-1.5,0.2-2.8-0.7-3.1-2.2c0-0.3,0-0.6,0-0.9V3.4c-0.1-0.8,0.2-1.7,0.7-2.3
	c1.1-1.3,3-1.4,4.3-0.3c0.8,0.7,1.1,1.6,1,2.6v43.1c0.1,0.8-0.2,1.7-0.7,2.3C154.2,49.4,153.4,49.7,152.6,49.6z'/>
                    <path id='Path_13' className='st0' d='M179.2,49.7c-5.6,0-9.7-1.3-12.3-4s-4-6.8-4-12.3V16.6c0-5.6,1.3-9.7,4-12.4
	c2.6-2.7,6.7-4,12.3-3.9h8.8c4.6,0,8,0.9,10.4,2.8s3.8,4.8,4.2,8.9c0.2,0.8,0,1.7-0.6,2.3c-0.6,0.6-1.5,0.9-2.3,0.8
	c-1.8,0-2.9-1-3.2-3.1c-0.2-2.2-1-3.7-2.3-4.5s-3.4-1.2-6.2-1.2h-8.8C177,6.4,175,6.6,173,7.3c-1.4,0.6-2.6,1.7-3.1,3.1
	c-0.7,2-1,4.1-0.9,6.2v16.7c-0.1,2.1,0.2,4.1,0.9,6.1c0.6,1.4,1.7,2.6,3.1,3.1c2,0.7,4,1,6.1,0.9h8.7c2.8,0,4.9-0.4,6.2-1.2
	c1.3-0.8,2.1-2.3,2.3-4.5c0.3-2,1.4-3.1,3.2-3.1c0.9-0.1,1.7,0.2,2.3,0.8c0.6,0.6,0.8,1.5,0.6,2.3c-0.4,4.1-1.8,7-4.2,8.9
	c-2.4,1.9-5.9,2.8-10.4,2.8L179.2,49.7z'/>
                </g>
                <path id='Path_14' className='st0' d='M242.9,49.4c-0.7,0.5-1.5,0.7-2.3,0.5c-0.8-0.2-1.5-0.8-1.9-1.5l-11.3-18.8h-10.2v16.6
	c0,2-1,3.1-3.1,3.1s-3.1-1-3.1-3.1V3.1c0-2,1-3.1,3.1-3.1h17c4.4,0,7.8,1.2,10.2,3.5s3.5,5.8,3.5,10.2v2.2c0,3.9-0.9,6.9-2.7,9.2
	c-1.9,2.4-4.7,3.9-7.8,4.3l9.5,15.9C245,47,244.6,48.4,242.9,49.4z M217.3,23.6h14c2.8,0,4.7-0.6,5.9-1.7c1.2-1.2,1.7-3.1,1.7-5.9
	v-2.2c0-2.7-0.6-4.7-1.7-5.9c-1.2-1.2-3.1-1.8-5.9-1.8h-14L217.3,23.6z'/>
                <path id='Path_15' className='st0' d='M371.1,49.7c-2,0-3.1-1-3.1-3.1V3.4c0-2,1-3.1,3.1-3.1c1.4,0,2.6,0.8,3.3,2L391,27.3l0,0
	l16.6-24.9c0.8-1.3,2.2-2,3.7-2c1.6,0,2.5,1,2.5,3.1v43.2c0,2-1,3.1-3.1,3.1c-2,0-3.1-1-3.1-3.1V12.9l0,0l-13.4,19.7
	c-0.8,1.3-2.2,2-3.7,2c-1.3-0.1-2.4-0.8-3-2l-13.5-19.8l0,0v33.8C374.1,48.6,373.1,49.7,371.1,49.7z'/>
                <path className='st0' d='M363.3,16.6v16.7c0,5.5-1.3,9.7-4,12.3c-2.6,2.7-6.8,4-12.3,4h-79.6c-5.5,0-9.6-1.3-12.3-4
	c-2.7-2.7-4-6.8-4-12.3V16.6c0-5.6,1.3-9.7,4-12.3c2.7-2.6,6.8-3.9,12.3-3.9H347c5.6,0,9.7,1.3,12.3,4S363.3,11.1,363.3,16.6z'/>
                <path className='st1' d='M287.2,16.6v16.7c0.1,2.1-0.2,4.2-0.9,6.1c-0.6,1.4-1.7,2.6-3.2,3.1c-1.9,0.7-4,1-6.1,0.9h-8.7
	c-2.1,0.1-4.2-0.2-6.1-0.9c-1.4-0.6-2.6-1.7-3.1-3.1c-0.7-2-1-4-0.9-6.1V16.6c-0.1-2.1,0.2-4.2,0.9-6.1c0.6-1.4,1.7-2.6,3.2-3.1
	c2-0.7,4-1,6.1-0.9h8.7c2.1-0.1,4.2,0.2,6.1,1c1.4,0.6,2.6,1.7,3.2,3.2C286.9,12.5,287.3,14.6,287.2,16.6z'/>
            </svg>

        </div>
    );
};

export default Loader;
