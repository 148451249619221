import React from 'react';

import './landing-music-block.scss';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

const LandingMusicBlock = ({data}) => {
    return (
        <div className='music__block landing__block'>
            <div className='music__block__text'>
                <h2 className='title'>MUSIC</h2>
                <h3 className='subtitle'>ROOM</h3>
                <p className='paragraph' dangerouslySetInnerHTML={{ __html: data && data}}/>
            </div>

            <AniLink className='music__block__action block__action' to='/services/' fade duration={0.7}>

                <span className='action__title'>ENTER</span>

                <div className='verticalLine'/>

                <div className='arrowIcon'/>

            </AniLink>
        </div>
    );
};

export default LandingMusicBlock;
