import {object} from 'prop-types';
import React from 'react';
import './landing-artist-card.scss';

const LandingArtistCard = ({artist}) => {
    const {avatar, name, surname, professions_items} = artist;
    return (
        <div className='artist__card'>
            <div className='artist__card__img' style={{backgroundImage: `url(${avatar})`}}
                 title={`${name} ${surname}'s image`}
            >
                <div className='artist__card__img__avatar'/>
            </div>
            <div className='artist__card__text'>
                <h2 className='name'>
                    {name} <br/> {surname}
                </h2>
                {professions_items && professions_items.length && (
                    <p className='instrument'>
                        {professions_items.map(p => p.title)}
                    </p>
                )}
            </div>
        </div>
    );
};

LandingArtistCard.propTypes = {
    artist: object.isRequired,
};

export default LandingArtistCard;
