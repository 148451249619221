import React from "react";
import './newsBannerBlock.scss'

const NewsBannerBlock = () => {

    const featuredNews = [
        {
            date: '04.05.2020',
            title: 'Excepteur sint occaecat cupid non proident',
            isVideo: false,
            img: 'https://miro.medium.com/max/3182/1*ZdpBdyvqfb6qM1InKR2sQQ.png'
        },
        {
            date: '04.05.2020',
            title: 'Excepteur sint occaecat cupid non proident',
            isVideo: true,
            img: 'https://data.whicdn.com/images/336594178/original.jpg'
        }
    ]

    return (
        <div className='newsBanner'>
            <div className='lineDiv'/>
            <div className='newsBannerContent'>
                <div className='newsBannerContentTitle'>
                    <h3 className='titleText'>News</h3>
                    <h2 className='subtitleText strokeText'>Room</h2>
                    <p className='descriptionText'>
                        Nam posuere sit amet diam sit amet sodales. <br/>
                        News & updates and gallery room.
                    </p>
                </div>
                <div className='newsBannerContentInfo'>
                    <div className='newsBannerContentInfoNotice'>
                        <h5>ANNOUCEMENT</h5>
                        <h4>Excepteur sint occaecat cupidatat non proident</h4>
                        <p>Music Room is a company specilized in classical music artist cooperation management. It aims
                            to unite the interests of artists and project/event organizers in order to ensure</p>
                    </div>
                    {featuredNews?.map(item =>
                        <div className='newsBannerContentInfoNews'>
                            <div className='featuredNews'>
                                <div className='backgroundImage' style={{backgroundImage: `url(${item.img})`}}/>
                                <div className='colorLayer'/>
                                <div className='featuredNewsContent'>
                                    {item.isVideo && <div className='playButton'/>}
                                    <p className='newsDate'>{item.date}</p>
                                    <p className='newsTitle'>{item.title}</p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default NewsBannerBlock;