import React from "react";
import './footer.scss';
import SVGIcon from "../SVGIcon/SVGIcon";

const Footer = () => {
    const contactInfo = [
        {
            name: 'instagram',
            link: 'https://www.instagram.com/'
        },
        {
            name: 'facebook',
            link: 'https://www.facebook.com/'
        },
        {
            name: 'youtube',
            link: 'https://www.youtube.com/'
        },
        {
            name: 'linkedin',
            link: 'https://www.linkedin.com/'
        }
    ]

    return (
        <footer id="footer">
            <div className='footerBody'>
                <div className="footerBodyTop">
                    <div className='footerBodyTopLogo'/>
                    <div className='footerBodyTopContactInfo'>
                        <p>
                            CONTACT <br/> INFORMATION
                        </p>
                        <ul>
                            <li className='linkText'>
                                <a href="mailto:info@musicroom.info" target="_blank"
                                   rel='noreferrer noopener'>info@musicroom.info</a>
                            </li>
                            <li className='linkText'>
                                <a href="tel:+37444310324" target='_blank' rel='noreferrer noopener'>+374 44 310324</a>
                            </li>
                        </ul>

                        <ul className='desktop_d_none'>
                            <li className='linkText'>
                                <a href="/privacy/" target='_blank' rel='noreferrer noopener'>
                                    Privacy
                                </a>
                            </li>
                        </ul>

                        {/*<ul className='footerBodyTopContactInfoSocial'>*/}
                        {/*    {contactInfo?.map((contact,i) =>*/}
                        {/*        <li key={i}>*/}
                        {/*            <SVGIcon name={contact.name} link={contact.link}/>*/}
                        {/*        </li>*/}
                        {/*    )}*/}
                        {/*</ul>*/}


                    </div>
                </div>
                <div className='footerBodySeparateLine'/>
                <div className='footerBodyBottom'>
                    <div className='rightsReserved'>All Rights Reserved {new Date().getFullYear()}</div>
                    <div className='mobile_d_none'>
                        <ul>
                            <li className='linkText'>
                                <a href="/privacy/" target='_blank' rel='noreferrer noopener'>
                                    Privacy
                                </a>
                            </li>
                            {/*<li className='linkText'>*/}
                            {/*    <a href="#">*/}
                            {/*        Personal Info*/}
                            {/*    </a>*/}
                            {/*</li>*/}
                            {/*<li className='linkText'>*/}
                            {/*    <a href="#">*/}
                            {/*        Terms & Conditions*/}
                            {/*    </a>*/}
                            {/*</li>*/}
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
