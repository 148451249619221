import React, {useRef, useState} from "react";
import "./accordion.scss";

const Accordion = (props) => {
    const [setActive, setActiveState] = useState('');
    const [setHeight, setHeightState] = useState('0px');
    const content = useRef(null)

    const toggleAccordion = () => {
        setActiveState(setActive === '' ? 'active' : '');
        setHeightState(setActive === 'active' ? '0px' : `${content.current.scrollHeight}px`)
    }

    return (
        <div className="accordion__section">
            <button className={`accordion ${setActive}`} onClick={toggleAccordion}>
                {props.title}
            </button>
            <div ref={content} style={{maxHeight: `${setHeight}`}} className="accordion__content">
                {props.children}
            </div>
        </div>
    );
}

export default Accordion;