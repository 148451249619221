import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import './pastProjectsListing.scss';
import sizeMe from 'react-sizeme';
import Masonry from "react-responsive-masonry"
import SVGLoader from "../SVGLoader/SVGLoader";
import useCount from "../../../hooks/useCounts";
import {Accordion, ProjectCardLayout} from "../../index";
import {RiArrowDropDownLine} from "react-icons/ri/index";
import useEventListener from "../../../hooks/useEventListener";

const PastProjects = (props) => {
    const {size: {width}} = props;
    const count = useCount();
    const infiniteRef = useRef(null);
    const stackGridRef = useRef(null);
    const [marginValue, setMarginValue] = useState(null);
    const [lastPage, setLastPage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [projectsPage, setProjectsPage] = useState(2);
    const [pastProjects, setPastProjects] = useState(null);
    const [filters, setFilters] = useState([]);
    const [filter, setFilter] = useState(null);
    const [blockInView, setBlockInView] = useState(false);
    const [showFilterName, setShowFilterName] = useState(null);
    const [year, setYear] = useState(null);
    const [initialFilters, setInitialFilters] = useState([]);
    const [lastId, setLastId] = useState(null);
    const dropRef = useRef();

    const API_URL = 'https://cms.musicroom.info/api/projects?';

    // const API_URL = 'https://cms-musicroom.codics.solutions/api/projects?';

    const getProjects = (page = 1, str = '') => {
        return fetch(`${API_URL}page=${page}&status=past${str}`)
            .then(res => res.json())
            .then(res => {
                setLastPage(res.last_page);
                return res;
            })
    }

    const showFilter = (filterName) => {
        filterName === showFilterName ? setShowFilterName(null) : setShowFilterName(filterName);
    }

    useEffect(() => {
        setMarginValue((stackGridRef.current.offsetWidth * 0.18) / 3);
    }, [props.size, filters, filter])

    const filterByProject = (id) => {

        if (filter !== id) {
            setFilter(id)
        } else {
            setFilter(null)
        }

        // let arr = [];

        // if (filters.includes(id)) {
        //     setFilters(filters.filter(item => item !== id))
        //     setProjectsPage(2);
        //     setBlockInView(false);
        // } else {
        //     arr = [...filters, id];
        //     if (!arrEq(initialFilters, arr)) {
        //         setFilters(arr)
        //         setProjectsPage(2);
        //         setBlockInView(false);
        //     } else {
        //         const elem = document.getElementById(`pastProjectFilter${id}`).classList;
        //         elem.remove('lastCheck', 'animate')
        //         setTimeout(() => {
        //             elem.add('lastCheck', 'animate');
        //         }, 300)
        //     }
        // }
    }

    useEffect(() => {
        let categoryStr = '';
        let yearStr = '';

        if (filters.length) {
            filters?.map(e => {
                categoryStr += `&category[]=${e}`
            })
        }

        if (filter === null) {
            categoryStr = ""
        } else {
            categoryStr = `&category[]=${filter}`
        }

        if (year) {
            yearStr = `&year=${year}`
        }

        getProjects(1, `${categoryStr}${yearStr}`).then(res => {
            const data = res.data ? res.data : res;
            !arrEq(initialFilters, filters) && setPastProjects(data);
        })
    }, [filters, filter, year])

    const handleLoadMore = () => {
        setLoading(true);

        if (projectsPage >= lastPage + 1) {
            setLoading(false);
            setBlockInView(false);
            return false;
        } else {
            if (filters.length) {
                let str = '';
                filters?.map(e => {
                    str += `&category[]=${e}`
                })
                getProjects(projectsPage, str).then((newPage) => {
                    const finalData = newPage && newPage.data ? newPage.data : newPage;
                    setLoading(false);
                    setBlockInView(false);
                    setPastProjects([...pastProjects, ...finalData]);
                }).then(() => setProjectsPage(projectsPage + 1));
            } else {
                getProjects(projectsPage).then((newPage) => {
                    const finalData = newPage && newPage.data ? newPage.data : newPage;
                    setLoading(false);
                    setBlockInView(false);
                    setPastProjects([...pastProjects, ...finalData]);
                }).then(() => setProjectsPage(projectsPage + 1));
            }
        }
    }

    // const infiniteRef = useInfiniteScroll({
    //     loading,
    //     hasNextPage: true,
    //     onLoadMore: handleLoadMore,
    //     scrollContainer: 'window',
    // });

    const offset = (el) => {
        const rect = el.getBoundingClientRect(),
            scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
            scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        return {top: rect?.top + scrollTop, left: rect?.left + scrollLeft}
    }

    const scrollToElement = (queryParam) => {
        const elem = document.getElementById(queryParam);
        window.scrollTo({
            top: offset(elem).top,
            behavior: 'smooth'
        })
    }

    useLayoutEffect(() => {
        window.location.search && setTimeout(() => {
            const urlParams = new URLSearchParams(window.location.search);
            const myParam = urlParams.get('section');
            myParam ? scrollToElement(myParam) : window.scrollTo({top: 0, behavior: 'smooth'});
        }, 700)
    }, [])

    function seeMoreLoad() {
        return false;
    }

    const gambitGalleryIsInView = el => {
        if (el) {
            const scroll = window.scrollY || window.pageYOffset
            const boundsTop = el.getBoundingClientRect().bottom + scroll;

            const viewport = {top: scroll, bottom: scroll + window.innerHeight}
            const bounds = {top: boundsTop, bottom: boundsTop + el.clientHeight}

            return (bounds.bottom >= viewport.top && bounds.bottom <= viewport.bottom) || (bounds.top <= viewport.bottom && bounds.top >= viewport.top);
        }
    }

    useEffect(() => {
        window?.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [filters, filter])

    const handleScroll = () => {
        const elem = infiniteRef.current;
        if (elem && gambitGalleryIsInView(elem) && !blockInView) {
            setBlockInView(true);
        }
    }

    useEffect(() => {
        blockInView && (projectsPage - 1 >= 5 ? seeMoreLoad() : handleLoadMore());
    }, [blockInView])

    const filterByYear = (yearValue) => {
        setShowFilterName(null);
        year === yearValue ? setYear(null) : setYear(yearValue);
    }

    useEffect(() => {
        const data = [];

        count?.projects_past && Object.keys(count.projects_past).map((key, i) => {
            count.projects_past[key].map((item, j) => {
                data.push(item.id);
            })
        })

        data && setInitialFilters(data);
    }, [count])

    const arrEq = (arr1, arr2) => {
        let result = true;

        if (!arr1.length) return false;

        if (arr1.length !== arr2.length) {
            result = false;
        }

        arr1.forEach(item => {
            if (!arr2.includes(item)) {
                result = false
            }
        })


        return result;
    }

    useEventListener('click', (event) => handleMenuOutside(event));

    function handleMenuOutside(e) {
        const {target} = e;
        const {current} = dropRef;

        if (!current?.contains(target)) {
            if (!target.classList.contains('filterItemWithDropdownName')) {
                setShowFilterName(null);
            }
        }
    }

    return (
        <div className='pastProjects' id='past'>
            <div className='lineDiv'/>

            <div className='pastProjectsWrapper'>

                <div className='pastProjectsWrapperList'>
                    <div className='pastProjectsWrapperListContainer' ref={infiniteRef}>
                        <div className='upcomingProjectsHeaderTitle mobile_d_none'>
                            <h2>
                                Past Projects in <br/>
                                <span className='strokeText'>musicroom</span>
                            </h2>
                        </div>
                        <div className='pastProjectsWrapperListItems' ref={stackGridRef}>
                            {pastProjects?.length
                                ?
                                <Masonry
                                    columnsCount={width > 768 ? 3 : width > 480 ? 2 : 1}
                                    gutter={marginValue + 'px'}>
                                    {pastProjects?.length && pastProjects.map((project, j) =>
                                        <ProjectCardLayout item={project} key={'projectItem' + j}/>
                                    )}
                                </Masonry>
                                :
                                <div className='notificationWrapper'>
                                    Select at least one category to see our projects
                                </div>
                            }
                            {projectsPage - 1 >= 5 && !(projectsPage >= lastPage + 1) && !loading &&
                            <div onClick={handleLoadMore} className='seeMoreButton'>
                                See More
                            </div>
                            }
                        </div>
                        {loading &&
                        <SVGLoader/>
                        }
                    </div>
                </div>

                <div className='pastProjectsWrapperNavigationMenu'>
                    <div className='headSection'>
                        <p>Past</p>
                    </div>
                    <div className='upcomingProjectsHeaderTitle desktop_d_none'>
                        <h2>
                            Past Projects in <br/>
                            <span className='strokeText'>musicroom</span>
                        </h2>
                    </div>
                    <div className='contentSection'>
                        <div className='pastProjectsHeaderFilterItems'>
                            <span
                                className={`mobile_d_none filterItemWithDropdown  dropdownYear ${showFilterName === 'dateFilter' ? 'active' : ''}`}>
                                <span className='filterItemWithDropdownName' onClick={() => showFilter('dateFilter')}>
                                    Select a year <span className='selector__arrow'><RiArrowDropDownLine/></span>
                                </span>
                                <div className='filterItemWithDropdownWrapper' ref={dropRef}>
                                    {count?.projects_years?.past?.map((yearVal, i) =>
                                        <div className={year === yearVal.date ? '' : 'unChecked'} key={i}
                                             onClick={() => filterByYear(yearVal.date)}>
                                            {/*<span className='checkBox'/>*/}
                                            <span>{yearVal.date}</span>
                                        </div>
                                    )}
                                </div>
                            </span>

                            <span className='desktop_d_none'>
                                <Accordion title={
                                    <span className='filterItemWithDropdownName'>
                                    Select a year <span className='selector__arrow'><RiArrowDropDownLine/></span>
                                </span>
                                }>
                                    <div className='filterItemWithDropdownWrapper'>
                                        {count?.projects_years?.past?.map((yearVal, i) =>
                                            <div className={year === yearVal.date ? '' : 'unChecked'} key={i}
                                                 onClick={() => filterByYear(yearVal.date)}>
                                                {/*<span className='checkBox'/>*/}
                                                <span>{yearVal.date}</span>
                                            </div>
                                        )}
                                    </div>
                                </Accordion>
                            </span>

                            {count?.projects_past &&
                            Object.keys(count.projects_past).map((keyName, i) =>
                                <span className={`filterItemWithDropdown pastFiltersContainer active`} key={i}>
                                    <div className='filterItemWithDropdownWrapper'>
                                        {count.projects_past[keyName].map((item, j) =>
                                            <div key={i + '' + j}
                                                 id={`pastProjectFilter${item.id}`}
                                                //  className={`${filters.includes(item.id) ? 'unChecked' : ''} pastFiltersItem`}
                                                 className={`${filter === item.id ? 'checked' : ''} pastFiltersItem`}
                                                 onClick={() => filterByProject(item.id)}
                                            >
                                                {/* <span className='checkBox'/> */}
                                                <span>
                                                    {item.title}
                                                    {/*{item.count < 10 && item.count !== 0 ? `0${item.count}` : item.count}*/}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </span>
                            )}
                        </div>

                        {/*<div className='upcomingProjectsHeaderFilterItems'>*/}
                        {/*    {count?.projects_past?.map((item, i) =>*/}
                        {/*        <div className={filters?.includes(item.id) ? 'unChecked' : ''} key={i}*/}
                        {/*             onClick={(e) => filterByProject(item.id)}>*/}
                        {/*            <span className='checkBox'/>*/}
                        {/*            <span>{item.count} {item.title}</span>*/}
                        {/*        </div>*/}
                        {/*    )}*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default sizeMe()(PastProjects);
