import React, {Fragment, useEffect} from 'react';
import { object } from 'prop-types';
import { getDate } from '../../../utils';

import './project-card.scss';
// import { Link } from 'gatsby';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

const ProjectCard = ({ data, artistParam }) => {
  function removeDuplicates (myArr, prop) {
    return myArr.filter((obj, pos, arr) => {
      return (obj.avatar !== null && obj.name !== null) && arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
  }

  const joinConcertsArtists = (concerts) => {
    const data = concerts.reduce((acc, concert) => {
      return [...acc, ...concert.artists];
    }, []);

    return removeDuplicates(data, 'id');
  };

  const { slug, title, image, start_date, end_date, type, category } = data !== undefined && data !== null && data;

  const artistsCount = data?.artists?.count;
  const artistsData = data?.artists?.artists;

  return (
        <AniLink to={artistParam} className='concert' fade duration={0.7}>
            <div
                className='concert__image'
                style={{ backgroundImage: `url(${image})` }}
            />
            <div className='concert__date'>
                <div>
                    <h3 className='day'>{getDate(start_date).day}</h3>
                    <p className='month'>{getDate(start_date).month}</p>
                </div>
                {end_date && type === 'event' &&
                <div className='endDateBlock'>
                    <span>-</span>
                    <h3 className='day'>{getDate(end_date).day}</h3>
                    <p className='month'>{getDate(end_date).month}</p>
                </div>
                }
                <p className='projectItemBodyHeadDateYear'>{new Date(start_date).getFullYear()}</p>
            </div>
            <div className='concert__text'>
                <h2 className='title'>{title}</h2>
                {/* {time && <p className='time'>Starts at {time}</p>} */}
            </div>

            <div className='concert__category'>
                <p className='category'>{category}</p>
            </div>

            {artistsCount && artistsData?.length &&
                <div className='concert__artists'>
                    <span>With</span>
                    <div className='list'>
                        {artistsData.map((artist, i) =>
                            <Fragment key={i}>
                                <img
                                    key={`${artist.name}-${artist.surname}-${i}`}
                                    src={artist.avatar}
                                    alt={`${artist.name}-${artist.surname}`}
                                />
                                {artistsData.length === 1 &&
                                    <span className='artistSmallName'>{artist.name} <br/> {artist.surname}</span>
                                }
                            </Fragment>
                        )}
                    </div>

                    {artistsCount && artistsCount > 3 &&
                        <span className='others'>+{artistsCount-3}</span>
                    }
                </div>
            }
        </AniLink>
  );
};

ProjectCard.propTypes = {
  data: object.isRequired,
};

export default ProjectCard;
