import React, {createContext, useEffect} from 'react';
import PropTypes from 'prop-types';
import Header from './complex/header';
import Footer from './complex/footer';
import Loader from './complex/loader';
import Cookie from './complex/cookie';

export const AppContext = createContext(null);

const Layout = ({children, ...props}) => {
    useEffect(() => {
        document.querySelector('body').classList.remove('hideScroll');
    }, []);

    return (
        <AppContext.Provider
            value={{
                appProps: props,
            }}
        >
            <div className='App'>
                <Header/>
                <Loader/>
                <div className='mainContentWrapper'>
                    <main>{children}</main>
                </div>
                <Footer/>
                <Cookie/>
            </div>
        </AppContext.Provider>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
