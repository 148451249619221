import React, {useEffect, useRef, useState} from 'react';
import './upcomingProjects.scss';
import UpcomingProjectsSlider from '../upcomingProjectsSlider';
import useCount from '../../../hooks/useCounts';
import {RiArrowDropDownLine} from 'react-icons/ri';
import useEventListener from "../../../hooks/useEventListener";
import {Accordion} from "../../index";

const UpcomingProjects = () => {
    const count = useCount();
    const [upcomingProjects, setUpcomingProjects] = useState(null);
    const [filters, setFilters] = useState([]);
    const [year, setYear] = useState(null);
    const [showFilterName, setShowFilterName] = useState(null);
    const dropRefYear = useRef();
    const currentYear = useRef();
    const dropRefFilter = useRef();

    const filterByProject = (id) => {
        setShowFilterName(null);
        filters.includes(id)
            ? setFilters(filters.filter((item) => item !== id))
            : setFilters([...filters, id]);
    };

    useEffect(() => {
        let activeFilters = '';
        let activeYear = '';

        if (filters.length) {
            filters?.map((e) => {
                activeFilters += `&category[]=${e}`;
            });
        } else {
            activeFilters = '';
        }

        if (year) {
            activeYear = `&year=${year}`
        }

        getProjects(`${activeYear}${activeFilters}`);
    }, [filters, year]);

    const filterByYear = (yearValue) => {
        year === yearValue ? setYear(null) : setYear(yearValue);
    }

    const getProjects = (str = '') => {
        fetch(`${process.env.API_URL}/api/projects?status=upcoming${str}`)
            .then((res) => res.json())
            .then((res) => {
                const data = res.data ? res.data : res;

                setUpcomingProjects(data);
            });
    };

    const showFilter = (filterName) => {
        filterName === showFilterName ? setShowFilterName(null) : setShowFilterName(filterName);
    }

    // useEventListener('click', (event) => handleMenuOutsideYear(event));

    useEventListener('click', (event) => handleMenuOutsideFilter(event));

    // function handleMenuOutsideYear(e) {
    //     const {target} = e;
    //     const yearCurrent = dropRefYear.current;
    //
    //     if (yearCurrent && !yearCurrent.contains(target)) {
    //         if (!target.classList.contains('filterItemWithDropdownName')) {
    //             setShowFilterName(null);
    //         }
    //     }
    // }

    function handleMenuOutsideFilter(e) {
        const {target} = e;
        const filterCurrent = dropRefFilter.current;
        const yearCurrent = dropRefYear.current;

        if (!filterCurrent?.contains(target) && !yearCurrent?.contains(target)) {
            if (!target.classList.contains('filterItemWithDropdownName')) {
                setShowFilterName(null);
            }
        }
    }

    return (
        <div className='upcomingProjects' id='upcoming'>
            <div className='upcomingProjectsHeader'>

                <div className='upcomingProjectsHeaderTitle'>
                    <h2>
                        Upcoming Projects in <br/>
                        <span className='strokeText'>musicroom</span>
                        {/*<p className='projectYear'>{new Date().getFullYear()}</p>*/}
                    </h2>
                </div>

                <div className='upcomingProjectsHeaderFilter'>
                    <p>Upcoming</p>

                    {/*<div className='upcomingProjectsHeaderFilterItems'>
                        {count?.projects_upcoming?.map((item, i) => (
                            <div
                                key={i}
                                className={!filters.includes(item.id) ? 'checked' : ''}
                                onClick={(e) => filterByProject(item.id)}
                            >
                                <span className='checkBox'/>
                                <span>
                  {item.title} (
                                    {item.count < 10 && item.count !== 0
                                        ? `0${item.count}`
                                        : item.count}
                                    )
                </span>
                            </div>
                        ))}
                    </div> */}

                    <div className='upcomingProjectsHeaderFilterItems noOnMobile'>
                        <span className={`filterItemWithDropdown ${showFilterName === 'dateFilter' ? 'active' : ''}`}>
                            <span className='filterItemWithDropdownName' onClick={() => showFilter('dateFilter')}>
                                Select a year
                                <span className='selector__arrow'>
                                    <RiArrowDropDownLine/>
                                </span>
                            </span>
                            <div className='filterItemWithDropdownWrapper' ref={dropRefYear}>
                                {count?.projects_years?.upcoming?.map((yearVal, i) =>
                                    <div key={i} className={year === yearVal.date ? 'checked' : ''}
                                         onClick={() => filterByYear(yearVal.date)}>
                                        {/*<span className='checkBox'/>*/}
                                        <span>{yearVal.date}</span>
                                    </div>
                                )}
                            </div>
                        </span>
                        {count?.projects_upcoming &&
                        Object.keys(count.projects_upcoming).map((keyName, i) => {
                            const filterItem = count.projects_upcoming[keyName];
                            return (filterItem.length === 1 && filterItem[0]['single']) ?
                                <span className={`filterItemWithDropdown`} key={i}>
                                    <div key={i + 'singleItem'}
                                         className={`singleItem ${filters.includes(filterItem[0].id) ? 'checked' : ''}`}
                                         onClick={(e) => filterByProject(filterItem[0].id)}
                                    >
                                    {/*<span className='checkBox'/>*/}
                                        {/*<span>{filterItem[0].title} ({filterItem[0].count < 10 && filterItem[0].count !== 0 ? `0${filterItem[0].count}` : filterItem[0].count})</span>*/}
                                        <span>{filterItem[0].title}</span>
                                </div>
                                </span>
                                :
                                <span className={`filterItemWithDropdown ${showFilterName === keyName ? 'active' : ''}`}
                                      key={i}>
                                    <span className='filterItemWithDropdownName' onClick={() => showFilter(keyName)}>
                                        {keyName.replaceAll('_', ' ')}
                                        <span className='selector__arrow'>
                                            <RiArrowDropDownLine/>
                                        </span>
                                    </span>
                                    <div className='filterItemWithDropdownWrapper' ref={dropRefFilter}>
                                        {count.projects_upcoming[keyName].map((item, j) =>
                                            <div
                                                key={i + '' + j}
                                                className={filters.includes(item.id) ? 'checked' : ''}
                                                onClick={(e) => filterByProject(item.id)}
                                            >
                                                {/*<span className='checkBox'/>*/}
                                                <span>
                                                      {item.title}
                                                    {/*({item.count < 10 && item.count !== 0 ? `0${item.count}` : item.count})*/}
                                                    </span>
                                            </div>
                                        )}
                                    </div>
                                </span>
                        })}
                    </div>


                    <div className='upcomingProjectsHeaderFilterItems desktop_d_none'>
                        <span className={`filterItemWithDropdown`}>
                            <Accordion title={<span className='filterItemWithDropdownName'>
                                                    Select a year
                                                    <span className='selector__arrow'>
                                                        <RiArrowDropDownLine/>
                                                    </span>
                                                </span>}>
                                <div className='filterItemWithDropdownWrapper'>
                                {count?.projects_years?.upcoming?.map((yearVal, i) =>
                                    <div key={i} className={year === yearVal.date ? 'checked' : ''}
                                         onClick={() => filterByYear(yearVal.date)}>
                                        <span>{yearVal.date}</span>
                                    </div>
                                )}
                            </div>
                            </Accordion>
                        </span>
                        {count?.projects_upcoming &&
                        Object.keys(count.projects_upcoming).map((keyName, i) => {
                            const filterItem = count.projects_upcoming[keyName];
                            return (filterItem.length === 1 && filterItem[0]['single']) ?
                                <span className={`filterItemWithDropdown`} key={i}>
                                    <div key={i + 'singleItem'}
                                         className={`singleItem ${filters.includes(filterItem[0].id) ? 'checked' : ''}`}
                                         onClick={(e) => filterByProject(filterItem[0].id)}
                                    >
                                    {/*<span className='checkBox'/>*/}
                                        {/*<span>{filterItem[0].title} ({filterItem[0].count < 10 && filterItem[0].count !== 0 ? `0${filterItem[0].count}` : filterItem[0].count})</span>*/}
                                        <span>{filterItem[0].title}</span>
                                </div>
                                </span>
                                :
                                <span className={`filterItemWithDropdown`} key={i}>
                                    <Accordion title={<span className='filterItemWithDropdownName'>
                                                        {keyName.replaceAll('_', ' ')}
                                        <span className='selector__arrow'>
                                                            <RiArrowDropDownLine/>
                                                        </span>
                                                    </span>}>
                                        <div className='filterItemWithDropdownWrapper'>
                                        {count.projects_upcoming[keyName].map((item, j) =>
                                            <div key={i+'upcomingProjects'+j} className={filters.includes(item.id) ? 'checked' : ''}
                                                 onClick={(e) => filterByProject(item.id)}
                                            ><span>{item.title}</span>
                                            </div>
                                        )}
                                    </div>
                                    </Accordion>
                                </span>
                        })}
                    </div>
                </div>
            </div>

            <UpcomingProjectsSlider data={upcomingProjects && upcomingProjects}/>
        </div>
    );
};

export default UpcomingProjects;
