import React from 'react'
import './_SVGLoader.scss'

const SVGLoader = ({style}) => {
    return (
        <div className='loaderWrapper' style={style && style}>
            <div className="SVGLoader"/>
        </div>
    )
}

export default SVGLoader;