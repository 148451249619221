import React, { useEffect, useState } from 'react';
const useCount = () => {
  const [count, setCount] = useState(null);

  const getProjectsCount = () => {
    fetch(`${process.env.API_URL}/api/count`)
      .then((res) => res.json())
      .then((res) => {
        setCount(res);
      });
  };

  useEffect(() => {
    getProjectsCount();
  }, []);

  return count;
};

export default useCount;
