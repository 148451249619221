import React, {useEffect, useRef, useState} from 'react';
// import {array} from 'prop-types';
// import { navigate } from '@reach/router';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
// import {HiOutlineArrowRight} from 'react-icons/hi';
import LandingArtistCard from '../LandingArtistCard';
import './landing-artists-block.scss';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import SwiperCore, {Scrollbar} from 'swiper';

SwiperCore.use([Scrollbar]);

const LandingArtistsBlock = ({data, artists, ...props}) => {
    const [swiper, setSwiper] = useState(null);
    const swiperRef = useRef(null);

    useEffect(() => {
        if (
            swiper &&
            data &&
            swiperRef.current !== null &&
            swiperRef.current.swiper !== null
        ) {
            swiper.update();
            swiper
        }
    }, [data]);

    const swiperParams = {
        speed: 400,
        freeMode: true,
        spaceBetween: '2%',
        slidesPerView: '2',
    };

    return (
        <div className='artists__block landing__block'>
            <div className='artists__block__info'>
                <div className='artists__block__text'>
                    <h2 className='title'>ARTISTS</h2>
                    <h3 className='subtitle'>ROOM</h3>
                    <p className='paragraph' dangerouslySetInnerHTML={{__html: data && data}}/>
                </div>
                <AniLink
                    to='/artists/'
                    className='artists__block__action block__action'
                >
                    <span className='action__title'>ENTER </span>

                    <div className='arrowIcon'/>
                </AniLink>
            </div>

            <AniLink to={'/artists/'} fade duration={0.7} className='artists__block__side__content mobile_d_none'>
                {artists &&
                artists.length &&
                artists.map((artist) => (
                    <LandingArtistCard key={artist.id} artist={artist}/>
                ))}
            </AniLink>

            <AniLink to={'/artists/'} fade duration={0.7} className='artists__block__side__content desktop_d_none'>
                <Swiper
                    {...swiperParams}
                    ref={swiperRef}
                    onInit={(swiper) => setSwiper(swiper)}
                >
                {artists &&
                artists.length &&
                artists.map((artist, j) => (
                    <SwiperSlide key={j}>
                        <LandingArtistCard key={artist.id} artist={artist}/>
                    </SwiperSlide>
                ))}
                </Swiper>
            </AniLink>
        </div>
    );
};

export default LandingArtistsBlock;
