import AniLink from 'gatsby-plugin-transition-link/AniLink';
import React, {useEffect, useRef, useState} from 'react';
import LandingProjectCard from '../LandingProjectCard';
import './landing-projects-block.scss';
// import {array} from 'prop-types';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import SwiperCore, {Scrollbar} from 'swiper';

SwiperCore.use([Scrollbar]);

const LandingProjectsBlock = ({data, projects}) => {
    const [swiper, setSwiper] = useState(null);
    const swiperRef = useRef(null);

    const swiperParams = {
        speed: 400,
        freeMode: true,
        spaceBetween: '6%',
        slidesPerView: '1.1',
    };

    useEffect(() => {
        if (
            swiper &&
            data &&
            swiperRef.current !== null &&
            swiperRef.current.swiper !== null
        ) {
            swiper.update();
        }
    }, [data]);

    return (
        <div className='projects__block landing__block'>
            <div className='projects__block__info'>
                <div className='projects__block__text'>
                    <h2 className='title'>PROJECTS</h2>
                    <h3 className='subtitle'>ROOM</h3>
                    <p className='paragraph' dangerouslySetInnerHTML={{__html: data && data}}/>
                </div>
                <AniLink
                    to='/projects/'
                    className='projects__block__action block__action'
                    fade
                    duration={0.7}
                >
                    <span className='action__title'>ENTER </span>
                    <div className='arrowIcon'/>
                </AniLink>
            </div>
            <div className='projects__block__side__content mobile_d_none'>
                {projects &&
                projects.length &&
                projects.map(project => (
                    <LandingProjectCard key={project.id} project={project}/>
                ))}
            </div>

            <div className='projects__block__side__content desktop_d_none'>
                <div className='mobileSlider'>
                    <Swiper
                        {...swiperParams}
                        ref={swiperRef}
                        onInit={(swiper) => setSwiper(swiper)}
                    >
                        {projects &&
                        projects.length && projects.map((project, i) => (
                            <SwiperSlide key={i}>
                                <LandingProjectCard key={project.id} project={project}/>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </div>
    );
};

export default LandingProjectsBlock;
