import { Link } from 'gatsby';
import { object } from 'prop-types';
import React from 'react';

import './landing-project-card.scss';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

const LandingProjectCard = ({ project }) => {
  const { slug, image, start_date: date, title } = project;

  return (
    <AniLink to={`/projects/${slug}`} className='landing__project__card' fade duration={0.7}>
      <div
        className='landing__project__card__img'
        style={{ backgroundImage: `url(${image})` }}
      />


      <div className='landing__project__card__text'>
        <p className='date'>{date.split('-').reverse().join('.')}</p>
        <h2 className='title'>{title}</h2>
      </div>
    </AniLink>
  );
};

LandingProjectCard.propTypes = {
  project: object.isRequired,
};

export default LandingProjectCard;
