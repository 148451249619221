import React from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import './projectCardLayout.scss';

const ProjectCardLayout = ({item}) => {

    /*function removeDuplicates(myArr, prop) {
        return myArr.filter((obj, pos, arr) => {
            return (obj.avatar !== null && obj.name !== null) && arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
        });
    }

    const joinConcertsArtists = (concerts) => {
        const data = concerts.reduce((acc, concert) => {
            return [...acc, ...concert.artists]
        }, [])

        const all = removeDuplicates(data, 'id')

        if (all.length === 0) {
            return false;
        } else if (all.length === 1 && all[0].avatar) {
            return <div className='artistItemWithName'>
                <img src={all[0].avatar}
                     alt={all[0].name + ' ' + all[0].surname}/>
                <div>{all[0].name}<br/>{all[0].surname}
                </div>
            </div>
        } else if (all.length < 4) {
            return all.map((artist, i) =>
                artist.avatar &&
                <img className='artistItem'
                     src={artist.avatar}
                     alt={'sss'}
                     key={i + 'single'}/>
            )
        } else {
            const cutted = [...all];
            cutted.length = 3;

            return (
                <>
                    {cutted.map((artist, i) =>
                        artist.avatar &&
                        <img className='artistItem'
                             src={artist.avatar}
                             alt={'sss'}
                             key={i}
                        />
                    )}
                    <span>
                         +{all.length - 3}
                     </span>
                </>
            )
        }
    }*/

    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    // const {artists, artists_count} = item;
    const artists = item?.artists?.artists || item?.artists;
    const artists_count = item?.artists?.count || item?.artists_count;

    return (
        <div className='projectItem'>
            <AniLink
                className={`projectItemBody ${item.image ? 'projectItemBodyWithImageBg' : ''}`}
                to={`/projects/${item.slug}`}
                duration={0.7}
                fade
                style={{backgroundImage: `url('${item.image ? item.image : ''}')`}}>
                <div className='projectItemBodyHead'>
                    <div className='projectItemBodyHeadDate'>
                        <div>
                            <p className='projectItemBodyHeadDateDay'>{new Date(item.start_date).getDate() < 10 ? `0${new Date(item.start_date).getDate()}` : new Date(item.start_date).getDate()}</p>
                            <p className='projectItemBodyHeadDateMonth'>{monthNames[new Date(item.start_date).getMonth()].slice(0, 3)}</p>
                        </div>
                        {item.type === 'event' &&
                        <div className='endDate'>
                            <p className='projectItemBodyHeadDateDay'>{new Date(item.end_date).getDate() < 10 ? `0${new Date(item.end_date).getDate()}` : new Date(item.end_date).getDate()}</p>
                            <p className='projectItemBodyHeadDateMonth'>{monthNames[new Date(item.end_date).getMonth()].slice(0, 3)}</p>
                        </div>
                        }
                        <p className='projectItemBodyHeadDateYear'>{new Date(item.start_date).getFullYear()}</p>
                    </div>
                    <div className="arrow"/>
                </div>
                <div className='projectItemBodyContent'>
                    <p className='projectItemBodyContentTitle'
                       dangerouslySetInnerHTML={{__html: item.title}}/>
                    <p className='projectItemBodyContentCategory'>{item.category}</p>
                    {artists?.length
                        ?
                        <div className='projectItemBodyContentArtists'>
                            <span>With</span>
                            <div className='projectItemBodyContentArtistsList'>
                                <>
                                    {artists.map((artist, i) =>
                                        artist.avatar &&
                                        <img className='artistItem'
                                             src={artist.avatar}
                                             alt={'sss'}
                                             key={i}
                                        />
                                    )}
                                    <span className='artistItemName' dangerouslySetInnerHTML={{__html: artists_count === 1 ? `${artists[0].name} <br/> ${artists[0].surname}` : ``}}/>
                                    <span>
                                        {artists_count > 3 ? `+${+artists_count - 3}` : ``}
                                     </span>
                                </>
                            </div>
                        </div>
                        :
                        <p className='noArtists'>No artists</p>
                    }
                    {item.canceled ?
                        <p className='canceledFlag' style={{fontSize: '0.85vw', color: '#F75F41'}}>
                            CANCELED
                        </p>
                        :
                        ''
                    }
                </div>
            </AniLink>
        </div>
    )
}

export default ProjectCardLayout;