import React from "react";
import './latestNewsListing.scss';

const LatestNewsListing = () => {
    return (
        <div className='latestNews'>
            <div className='latestNewsContent'>
                <div className='latestNewsContentTitle'>
                    <h2>Latest News</h2>
                </div>
                <div className='latestNewsContentListing'>
                    <div className='latestNewsContentListingItem'>
                        <div className='latestNewsContentListingItemCover'>
                            <div className='latestNewsContentListingItemCoverImg' style={{backgroundImage: `url(/news/news1.png)`}}/>
                        </div>
                        <div className='latestNewsContentListingItemInfo'>
                            <p className='latestNewsContentListingItemInfoDate'>04.05.2020</p>
                            <h4 className='latestNewsContentListingItemInfoTitle'>Excepteur sint <br/> occaecat cupidatat
                                non proident</h4>
                            <p className='latestNewsContentListingItemInfoDesc'>Music Room is a company specilized in
                                classical music artist cooperation management. It aims to unite the interests of artists
                                and project/event organizers in order to ensure unique.</p>
                        </div>
                    </div>

                    <div className='latestNewsContentListingItem featured'>
                        <div className='latestNewsContentListingItemCover'>
                            <div className='latestNewsContentListingItemCoverImg' style={{backgroundImage: `url(/news/news1.png)`}}/>
                        </div>
                        <div className='latestNewsContentListingItemInfo'>
                            <p className='latestNewsContentListingItemInfoDate'>04.05.2020</p>
                            <h4 className='latestNewsContentListingItemInfoTitle'>Excepteur sint <br/> occaecat cupidatat <br/>
                                non proident</h4>
                            <p className='latestNewsContentListingItemInfoDesc'>Music Room is a company specilized in
                                classical music artist cooperation management. It aims to unite the interests of artists
                                and project/event organizers in order to ensure unique.</p>
                        </div>
                    </div>

                    <div className='latestNewsContentListingItem'>
                        <div className='latestNewsContentListingItemCover'>
                            <div className='latestNewsContentListingItemCoverImg' style={{backgroundImage: `url(/news/news3.png)`}}/>
                        </div>
                        <div className='latestNewsContentListingItemInfo'>
                            <p className='latestNewsContentListingItemInfoDate'>04.05.2020</p>
                            <h4 className='latestNewsContentListingItemInfoTitle'>Excepteur sint <br/> occaecat cupidatat <br/>
                                non proident</h4>
                            <p className='latestNewsContentListingItemInfoDesc'>Music Room is a company specilized in
                                classical music artist cooperation management. It aims to unite the interests of artists
                                and project/event organizers in order to ensure unique.</p>
                        </div>
                    </div>

                    <div className='latestNewsContentListingItem gallery'>
                        <div className='latestNewsContentListingItemCover'>
                            <div className='latestNewsContentListingItemCoverImg' style={{backgroundImage: `url(/news/news3.png)`}}/>
                        </div>
                        <div className='latestNewsContentListingItemCover'>
                            <div className='latestNewsContentListingItemCoverImg' style={{backgroundImage: `url(/news/news3.png)`}}/>
                        </div>
                        <div className='latestNewsContentListingItemCover'>
                            <div className='latestNewsContentListingItemCoverImg' style={{backgroundImage: `url(/news/news3.png)`}}/>
                        </div>
                        <div className='toGallery'>
                            <p>04.05.2020</p>
                            <h4>Festival Pictures</h4>
                            <div className='arrowIcon'/>
                        </div>
                    </div>

                    <div className='latestNewsContentListingItem'>
                        <div className='latestNewsContentListingItemCover'>
                            <div className='latestNewsContentListingItemCoverImg' style={{backgroundImage: `url(/news/news5.png)`}}/>
                        </div>
                        <div className='latestNewsContentListingItemInfo'>
                            <p className='latestNewsContentListingItemInfoDate'>04.05.2020</p>
                            <h4 className='latestNewsContentListingItemInfoTitle'>Excepteur sint <br/> occaecat cupidatat <br/>
                                non proident</h4>
                            <p className='latestNewsContentListingItemInfoDesc'>Music Room is a company specilized in
                                classical music artist cooperation management. It aims to unite the interests of artists
                                and project/event organizers in order to ensure unique.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LatestNewsListing;