import React from "react";
import './homeSlider.scss';
import SwiperCore, {A11y, Autoplay, EffectFade, Pagination} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

SwiperCore.use([Pagination, A11y, EffectFade, Autoplay]);

const HomeSlider = () => {
    const sliderData = [
        {
            imgSrc: '/homeSliderImages/slider1.jpg',
            imgSrcMobile: '',
            linkText: "Artists <br/>Room",
            linkUrl: "artists",
            leftSectionData: `    
<!--                            <p>Upcoming Event</p>-->
                            <h2>Great Concerts <br/> to be measured</h2>
                            <p>One-stop shop for smooth project facilitation and delivery</p>
            `,
        },
        {
            imgSrc: '/homeSliderImages/slider2.jpg',
            imgSrcMobile: '/homeSliderImages/Maxim-Vengerov.jpg',
            linkText: "Projects <br/>Room",
            linkUrl: "projects",
            leftSectionData: `    
<!--                            <p>Upcoming Events</p>-->
                            <h2>Great Concerts <br/> to be measured</h2>
                            <p>One-stop shop for smooth project facilitation and delivery</p>
            `,
        },
        {
            imgSrc: '/homeSliderImages/slider3.jpg',
            imgSrcMobile: '',
            linkText: "MusicRoom & <br> Services",
            linkUrl: "services",
            leftSectionData: `    
<!--                            <p>Upcoming Projects</p>-->
                            <h2>Great Concerts <br/> to be measured</h2>
                            <p>One-stop shop for smooth project facilitation and delivery</p>
            `,
        }
    ]

    return (
        <div className='homeSlider'>
            <Swiper
                loop={true}
                speed={2500}
                effect='fade'
                autoplay={{disableOnInteraction: false, delay: 5000}}
                slidesPerView={1}
                pagination={{clickable: true}}
                breakpoints={{
                    0: {
                        direction: 'horizontal',
                    },
                    1025: {
                        direction: 'vertical',
                    },
                }}>

                {sliderData?.map((sliderItem,i) =>
                    <SwiperSlide key={i}>
                            <div
                                style={{backgroundImage: `url(${sliderItem.imgSrc})`}}
                                className='homeSliderImg desktop'
                            >
                                <div className='homeSliderImgContent'>
                                    <div className='homeSliderImgContentBody'>
                                        <div className='homeSliderImgContentBodyLeft'>
                                            <div dangerouslySetInnerHTML={{__html: sliderItem.leftSectionData}}/>
                                            {/*<AniLink className="readMoreBtn" to={sliderItem.linkUrl} cover bg='#66339900' duration={50}>*/}
                                            {/*<AniLink className="readMoreBtn" to={sliderItem.linkUrl} fade duration={1.5} direction='left'>*/}
                                            {/*    Read More*/}
                                            {/*</AniLink>*/}
                                        </div>

                                        <div className='homeSliderImgContentBodyRight'>
                                            <AniLink to={sliderItem.linkUrl} fade duration={0.7} direction='left'>
                                                <p className='linkTitle' dangerouslySetInnerHTML={{ __html: sliderItem.linkText}}/>
                                                <div className='arrow'/>
                                                <p>Enter</p>
                                            </AniLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                style={{backgroundImage: `url(${sliderItem.imgSrcMobile ? sliderItem.imgSrcMobile : sliderItem.imgSrc})`}}
                                className='homeSliderImg mobile'
                            >
                                <div className='homeSliderImgContent'>
                                    <div className='homeSliderImgContentBody'>
                                        <div className='homeSliderImgContentBodyLeft'>
                                            <div dangerouslySetInnerHTML={{__html: sliderItem.leftSectionData}}/>
                                            {/*<AniLink className="readMoreBtn" to={sliderItem.linkUrl} cover bg='#66339900' duration={50}>*/}
                                            {/*<AniLink className="readMoreBtn" to={sliderItem.linkUrl} fade duration={1.5} direction='left'>*/}
                                            {/*    Read More*/}
                                            {/*</AniLink>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </SwiperSlide>
                )}
            </Swiper>
        </div>
    )
}

export default HomeSlider;
