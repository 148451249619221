import React from "react";
import './projectsSlider.scss';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import SwiperCore, {A11y, Autoplay, EffectFade, Pagination} from "swiper";
SwiperCore.use([Pagination, A11y, EffectFade, Autoplay]);

const Index = () => {

    const sliderData = [
        {
          imgSrc: '/homeSliderImages/projects-banner-1.jpg',
          textData: `<h2>GREAT PROJECTS TO BE MEASURED</h2>`,
      }

        // {
        //     imgSrc: '/homeSliderImages/slider3.jpg',
        //     textData: `<h2>GREAT PROJECTS TO BE MEASURED</h2>`,
        // },
        // {
        //     imgSrc: '/homeSliderImages/rayChen.jpg',
        //     textData: `<h2>GREAT PROJECTS TO BE MEASURED</h2>`,
        //     // textData: `<h2>Nulla finibus dolor sit amet MUZIK <br> BITTE sit @2020 LIVE</h2>`,
        // },
        // {
        //     imgSrc: '/homeSliderImages/slider3.jpg',
        //     textData: `<h2>GREAT PROJECTS TO BE MEASURED</h2>`,
        // }
    ]

    return (
        <div className='projectsSlider'>
            <Swiper
                loop={true}
                speed={1000}
                effect='fade'
                autoplay={{disableOnInteraction: false, delay: 5000}}
                slidesPerView={1}
                pagination={{clickable: true}}
                breakpoints={{
                    0: {
                        direction: 'horizontal',
                    },
                    1025: {
                        direction: 'vertical',
                    },
                }}>
                {sliderData?.map((sliderItem, i) =>
                    <SwiperSlide key={i}>
                        <div
                            style={{backgroundImage: `url(${sliderItem.imgSrc})`}}
                            className='homeSliderImg'
                        >
                            <div className='homeSliderImgContent'>
                                <div className='homeSliderImgContentBody'>
                                    <div className='homeSliderImgContentBodyLeft'>
                                        <div dangerouslySetInnerHTML={{__html: sliderItem.textData}}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                )}
            </Swiper>
        </div>
    )
}

export default Index;
