import React from 'react';
import {object, string} from 'prop-types';
import {getDate} from '../../../utils';
import './project-inner-card.scss';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import {HiOutlineArrowRight} from 'react-icons/hi';

const ProjectInnerCard = ({data, category}) => {
    const {
        title,
        date,
        time,
        other_artists,
        artists,
        ticket_link: ticketUrl,
        orchestra,
        location,
        country,
        canceled
    } = data !== undefined && data !== null && data;

    const {day, month, year} = date !== undefined && date !== null && getDate(date);

    console.log(artists);

    return (
        <>
            <div className='project__inner__card'>
                <div className='main__section'>
                    <div className='main__section__date'>
                        <div className='dates'>
                            <h3 className='day'>{day}</h3>
                            <p className='month'>{month}</p>
                            <p className='year'>{year}</p>
                        </div>
                    </div>
                    <div className='main__section__titles'>
                        <p className='category'>{category}</p>
                        <div>
                            <h2 className='title'>{title}</h2>
                            {ticketUrl &&
                            <a href={ticketUrl} target='_blank' rel='noreferrer' className='main__section__action'>
                                <span className='action__title'>Enter </span>
                                <button className='btn'>
                                    <HiOutlineArrowRight/>
                                </button>
                            </a>
                            }
                        </div>
                    </div>
                </div>
                {artists &&
                <div className='project__inner__card__artists'>
                    <span>With</span>
                    <div className='artists__list'>
                        {artists.map((artist, i) => (
                            artist.show_in_list
                                ? <AniLink to={`/artists/${artist.slug}`}
                                           fade
                                           duration={0.7}
                                           className='artist'
                                           key={`${artist.name}-${artist.surname}-${i}`}
                                >
                                    <img
                                        src={artist.avatar}
                                        alt={`${artist.name && artist.name} ${
                                            artist.surname && artist.surname
                                        }`}
                                    />

                                    <div className='artist__text'>
                                        <h3 className='name'>{`${artist.name && artist.name} ${
                                            artist.surname && artist.surname
                                        }`}</h3>
                                        <p className='instrument'>
                                            {artist.professions_items &&
                                            artist.professions_items.length &&
                                            artist.professions_items.map(profession => <span>{profession.title}</span>)}
                                        </p>
                                    </div>
                                </AniLink>
                                : <div key={`${artist.name}-${artist.surname}-${i}`} className='artist'>
                                    <img
                                        src={artist.avatar}
                                        alt={`${artist.name && artist.name} ${
                                            artist.surname && artist.surname
                                        }`}
                                    />

                                    <div className='artist__text'>
                                        <h3 className='name'>{`${artist.name && artist.name} ${
                                            artist.surname && artist.surname
                                        }`}</h3>
                                        <p className='instrument'>
                                            {artist.professions_items &&
                                            artist.professions_items.length &&
                                            artist.professions_items.map(
                                                (profession) => profession.title
                                            )}
                                        </p>
                                    </div>
                                </div>
                        ))}
                    </div>
                </div>
                }

                {location && (
                    <div className='project__inner__card__location'>
                        <span>Location</span>
                        <h3 className='location'>
                            {location}
                            {country && `, ${country}`}
                        </h3>
                    </div>
                )}

                {canceled ?
                    <div className='project__inner__card__location'>
                        <h3 className='location' style={{color: '#F75F41'}}>
                            Canceled
                        </h3>
                    </div>
                    :
                    ''
                }

                {time && (
                    <div className='project__inner__card__time'>
                        <span>START: </span>
                        <span className='time'>{time}</span>
                    </div>
                )}
            </div>
        </>
    );
};

ProjectInnerCard.propTypes = {
    data: object.isRequired,
    category: string.isRequired,
};

export default ProjectInnerCard;
