import React, {memo, useEffect, useRef, useState} from 'react';
import './header.scss';
import {VscClose, VscMenu} from 'react-icons/vsc';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import useCount from "../../../hooks/useCounts";

const navLinks = [
    {
        link: '/artists/',
        title: 'Artists Room —',
        external: false,
    },
    {
        link: '/projects/',
        title: 'Projects Room —',
        external: false,
    },
];

const menuData = [
    {
        desc: 'Great concerts to be measured',
        pageName: 'HOME',
        link: '',
    },
    {
        desc: '',
        pageName: 'musicroom & services',
        link: '/services',
        subLinks: [
            {
                name: 'MUSICROOM',
                hash: 'musicroom',
            },
            {
                name: 'Services',
                hash: 'services',
            },
        ],
    },
    {
        desc: 'Explore foremost classical musicians and emerging talents in MusicRoom.',
        pageName: 'Artists',
        link: '/artists',
        // subLinks: [
        //     {
        //         name: 'Violinists',
        //         hash: 'violinists',
        //         disabled: true,
        //     },
        //     {
        //         name: 'Pianists',
        //         hash: 'pianists',
        //         disabled: true,
        //     },
        //     {
        //         name: 'Composers',
        //         hash: 'composers',
        //         disabled: true,
        //     },
        //     {
        //         name: 'Celloists',
        //         hash: 'celloists',
        //         disabled: true,
        //     },
        //     {
        //         name: 'Others',
        //         hash: 'others',
        //         disabled: true,
        //     },
        // ],
    },
    {
        desc: '',
        pageName: 'Projects',
        link: '/projects',
        subLinks: [
            {
                name: 'Upcoming',
                hash: 'upcoming',
            },
            {
                name: 'Past',
                hash: 'past',
            }
        ],
    },
    {
        desc: 'At MusicRoom we listen to our clients’ wishes and act to make them happen.',
        pageName: 'Clients',
        link: '/?section=clients',
    },
];

const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const backdropRef = useRef(null);
    const headerSizeRef = useRef(null);
    const [windowLoc, setWindowLoc] = useState(null);
    const count = useCount();

    const offset = (el) => {
        const rect = el.getBoundingClientRect(),
            scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
            scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        return {top: rect?.top + scrollTop, left: rect?.left + scrollLeft}
    }

    const scrollToElement = (queryParam) => {
        const elem = document.getElementById(queryParam);
        window.scrollTo({
            top: offset(elem).top,
            behavior: 'smooth'
        })
    }

    useEffect(() => {
        if (window.navigator.userAgent.match(/Firefox\/([0-9]+)\./)) {
            backdropRef.current.classList.add('firefox');
        }
        setWindowLoc(window.location.pathname);
    }, []);

    const openMenu = (e, url) => {
        if (e) {
            url === window.location.pathname.replaceAll('/','') &&
            setTimeout(() => {
                const urlParams = new URLSearchParams(window.location.search);
                const myParam = urlParams.get('section');
                myParam ? scrollToElement(myParam) : window.scrollTo({top: 0, behavior: 'smooth'});
            }, 700)
        }
        document.querySelector('body').classList.toggle('hideScroll');
        setMenuOpen(!menuOpen);
    };

    const handleScroll = (headerHeight) => {
        const topPos = window.scrollY || window.scrollTop || document.getElementsByTagName("html")[0].scrollTop;

        if (topPos >= headerHeight) {
            backdropRef?.current?.classList.add('blurOn');
            headerSizeRef?.current?.classList.add('thinner');
        } else {
            backdropRef?.current?.classList.remove('blurOn');
            headerSizeRef?.current?.classList.remove('thinner');
        }
        // const scroll_direction = (document.body.getBoundingClientRect()).top > scroll_position ? 'up' : 'down';
        // scroll_position = (document.body.getBoundingClientRect()).top;


        // scroll_direction === 'up' ? headerSizeRef.current.classList.remove('thinner') :
    }

    useEffect(() => {
        const headerHeight = document.querySelector('header').clientHeight;

        window.addEventListener('scroll', () => handleScroll(headerHeight));

        return (() => window.removeEventListener('scroll', handleScroll))
    }, [])

    return (
        <>
            <header className='header'>
                <div className='header__background' ref={backdropRef}/>
                <div className='header__foreground' ref={headerSizeRef}>
                    <div className='header__navigation'>
                        <div className='header__navigation__links'>
                            {navLinks &&
                            navLinks.map((link, i) => (
                                <AniLink
                                    key={`${link.link}-${i}`}
                                    to={link.link}
                                    fade
                                    duration={0.7}
                                    className={`header__navigation__links__link linkText ${
                                        windowLoc?.match(link.link) ? 'active' : ''
                                    }`}
                                >
                                    {link.title}
                                </AniLink>
                            ))}
                        </div>

                        <div className='header__navigation__menu'>
                            <button
                                className='header__navigation__menu__btn'
                                data-menu-open={menuOpen}
                                onClick={() => openMenu(null)}
                            >
                <span className='header__navigation__menu__btn__text'>
                  MENU
                </span>
                                <span className='header__navigation__menu__btn__icon icon'>
                  <span className={menuOpen ? 'active' : ''}>
                    <VscClose/>
                  </span>
                  <span className={!menuOpen ? 'active' : ''}>
                    <VscMenu/>
                  </span>
                </span>
                            </button>
                        </div>
                    </div>

                    <AniLink to='/' fade duration={0.7} className='header__logo'/>
                </div>
            </header>

            <div className={`menu ${menuOpen ? 'show' : ''}`}>
                <div className='menuLinks'>
                    {menuData.map((menu, index) => (
                        <div
                            style={{width: `calc(100% / ${menuData.length})`}}
                            key={index}
                        >
                            <AniLink to={`${menu.link}/`} fade duration={0.7} onClick={(e) => openMenu(e, menu.link)}>
                                <p className='linkNumber'>{`0${index + 1}`}</p>
                                {menu.desc && <p className='linkDesc'>{menu.desc}</p>}
                                {menu.subLinks && (
                                    <div className='linkSubLinkList'>
                                        {menu.subLinks?.map((subLink, i) => (
                                            <AniLink
                                                key={`${menu.link}?section=${subLink.hash}-${i}`}
                                                className={`linkSubLink ${
                                                    subLink.disabled ? 'disabled' : ''
                                                }`}
                                                to={`${menu.link}?section=${subLink.hash}`}
                                                fade
                                                duration={0.7}
                                            >
                                                {subLink.name} {menu.link === "projects" && `(${subLink.hash === 'past' ? count?.past_count : subLink.hash === 'upcoming' ? count?.upcoming_count : ''})`}
                                            </AniLink>
                                        ))}
                                    </div>
                                )}
                                <div className='arrowLink'/>
                                <div className='pageInformation'>
                                    {/*{count && (menu.link === 'artists' || menu.link === 'projects') &&*/}
                                    {/*    <p className='linkPageNumber'>{typeof count[menu.link] === "number" ? count[menu.link] : count[menu.link].total}</p>*/}
                                    {/*}*/}
                                    <p className='linkPageName'>{menu.pageName}</p>
                                </div>
                            </AniLink>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default memo(Header);
