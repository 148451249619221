import React from 'react';
import './landing-partners-block.scss';
import SwiperCore, {A11y} from 'swiper';
import 'swiper/swiper-bundle.min.css';
import {Swiper, SwiperSlide} from 'swiper/react';

SwiperCore.use([A11y]);

const LandingPartnersBlock = ({data}) => {
    const partners = [
        {
            logo: "/images/partners/EFSC-logo_circle.png",
            url: "http://eufsc.eu/"
        },
        {
            logo: "/images/partners/EFSC-logo_circle.png",
            url: "http://eufsc.eu/"
        },
        {
            logo: "/images/partners/EFSC-logo_circle.png",
            url: "http://eufsc.eu/"
        },
        {
            logo: "/images/partners/EFSC-logo_circle.png",
            url: "http://eufsc.eu/"
        },
        {
            logo: "/images/partners/EFSC-logo_circle.png",
            url: "http://eufsc.eu/"
        },

    ]

    const swiperParams = {
        speed: 400,
        freeMode: true,
        spaceBetween: '6%',
        slidesPerView: '1.1',
    };

    console.log(data);

    return (
        <div className='partners__block landing__block'>
            <div className='partners__block__text'>
                <h2 className='title'>PARTNERS</h2>
                <h3 className='subtitle'>ROOM</h3>
                <p className='paragraph' dangerouslySetInnerHTML={{__html: data?.text}}/>
            </div>

            {/*<AniLink*/}
            {/*  to='/partners/'*/}
            {/*className='partners__block__action block__action'>*/}
            {/*  <span className='action__title'>ENTER </span>*/}
            {/*  <button className='btn'>*/}
            {/*    <HiOutlineArrowRight />*/}
            {/*  </button>*/}
            {/*</AniLink>*/}

            <div className="partners__block__side__content">
                <ul className='desktopSwiperPartners'>
                    {data?.items.map((item, i) => {
                        return (
                            <a href={item.link} target="_blank" key={i}>
                                <li>
                                    <img src={item.logo} alt={item.logo} className="partner-logo"/>
                                    <span className="partner-link">
                                        <span>Visit Website</span>
                                        <img src="/svgIcons/arrowRight.svg" alt=""/>
                                    </span>
                                </li>
                            </a>
                        )
                    })}
                </ul>
                <ul className='mobileSwiperPartners'>
                    <Swiper
                        {...swiperParams}
                    >
                        {data?.items?.map((item, i) => {
                            return (
                                <SwiperSlide key={i}>
                                    <a href={item.link} target="_blank">
                                        <li>
                                            <img src={item.logo} alt={item.logo} className="partner-logo"/>
                                            <span className="partner-link">
                                        <span>Visit Website</span>
                                        <img src="/svgIcons/arrowRight.svg" alt=""/>
                                    </span>
                                        </li>
                                    </a>
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                </ul>
            </div>
        </div>
    );
};

export default LandingPartnersBlock;
