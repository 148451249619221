import React, { Fragment, useEffect, useLayoutEffect, useState } from 'react';
import { VscClose, VscMenu } from 'react-icons/vsc';
import './cookie.scss';

const Cookie = () => {
  const [hideCookie, setHideCookie] = useState(false);
  const [disablePopup, setDisablePopup] = useState(false);

  const hideCookies = () => {
    setHideCookie(true);
    setCookie('cookieExist', 'true', 7);
  };

  const getCookie = (cname) => {
    const name = cname + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  };

  const setCookie = (cname, cvalue, exdays) => {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
  };

  useLayoutEffect(() => {
    const x = getCookie('cookieExist');
    x && setDisablePopup(true);
  }, []);

  return !disablePopup ? (
    <div className={`cookiesWrapper ${hideCookie ? 'hideCookie' : ''}`}>
      <div>
        <p>
          This website uses cookies in order to offer you the most relevant
          information.
        </p>

        <p>Please accept cookies for optimal performance.</p>
      </div>

      <div className='cookiesWrapperAccept' onClick={hideCookies}>
        Yes, I accept Cookies
      </div>

      <div className='close-cookies' onClick={hideCookies}>
        <VscClose />
      </div>
    </div>
  ) : (
    <Fragment />
  );
};

export default Cookie;
